function select() {
	const $selectGroup = '.js-select-group';
  const $select = '.js-select';
  const $optionSelect = '.js-option-select';
  const $options = '.js-options';
  const $optionsItem = '.js-options li';
  const open = 'is-open'

  $($select).on('click',function(e){
    const optionHeight = $(this).find($options).outerHeight();

    $($select).not(this).removeClass(open);
    $($select).not(this).parents($selectGroup).removeClass(open);
    $($select).not(this).find($options,this).slideUp();

    $(this).toggleClass(open);
    $(this).parents($selectGroup).toggleClass(open);
    $($options,this).slideToggle();

    if(window.innerWidth < 768) {
      if($($selectGroup).hasClass(open)) {
        $($select).not(this).parents($selectGroup).css({marginBottom: 0})
        $(this).parents($selectGroup).css({marginBottom: optionHeight})
      }else {
        $(this).parents($selectGroup).css({marginBottom: 0})
      }
    }
  });

  $(document).on('click', function (e) {
    if (!$(e.target).closest($select).length) {
      $($select).removeClass(open);
      $($selectGroup).removeClass(open);
      $($options).slideUp();

      if(window.innerWidth < 768) {
        $($selectGroup).css({marginBottom: 0})
      }
    }
  });

  $($options).on('click','li', function(){
    let selection = $(this).text();
    let dataValue = $(this).attr('data-value');
    $(this).parents($optionSelect).children('span').text(selection);
    $(this).parents($select).attr('data-selected-value',dataValue);

    if(selection !== "選択"){
      $(this).parents($select).children('.selected_value').val(dataValue)
    }else{
      $(this).parents($select).children('.selected_value').val("")
    }
  });

	$($optionsItem).on('click', function(){
		$('html,body').animate({
			scrollTop: $($selectGroup).offset().top - 100
		},500);
	});
}

export {select}
