export default function() {
	const $topBtn = $('.js-page-top');
	if($topBtn.length) {
		$topBtn.on('click', function () {
			$('body,html').animate({
				scrollTop: 0,
			}, 500);
			return false;
		});
	}

	$(window).on('scroll', function () {
		const scrollTop = $(window).scrollTop();
		if (scrollTop > 50) {
			$topBtn.fadeIn();
		} else {
			$topBtn.fadeOut();
		};
	})
}
