export default function() {
	$('.js-tab-group').each(function() {
		const tabGroup = '.js-tab-group';
		const tab = '.js-tab[role="tab"]';
		const panel = '.js-tabPanel[role="tabpanel"]';
		const is_current = 'is-current'

		$(this).find(tab).on('click', function(){
			let select = $(this).attr('aria-selected');
			let id = $(this).attr('aria-controls');

			if (select === 'true') {
				return false;
			}

			$(this).parents(tabGroup).find('.js-tab, .js-tabPanel').removeClass(is_current);
			$(this).parents(tabGroup).find(panel).attr('aria-hidden', true);
			$(this).parents(tabGroup).find(panel).attr('aria-hidden', true);
			$(this).parents(tabGroup).find('#' + id).attr('aria-hidden', false);
			$(this).parents(tabGroup).find('#' + id).addClass(is_current);
			$(this).parents(tabGroup).find(tab).attr('aria-selected', false);
			$(this).addClass(is_current);
			$(this).attr('aria-selected', true);

			if($('.js-inner-tab').length) {
				if($('.js-tabPanel').attr({'aria-hidden':'true'})) {
					$('.js-inner-tab, .js-inner-tabPanel').removeClass(is_current);
					$('.js-inner-tab[aria-selected="true"]').attr({'aria-selected':false});
					$('.js-inner-tabPanel[aria-hidden="false"]').attr({'aria-hidden':true});
					$(this).parents(tabGroup).find('#' + id).find('ul > li:nth-child(1) .js-inner-tab').addClass(is_current);
					$(this).parents(tabGroup).find('#' + id).find('ul > li:nth-child(1) .js-inner-tab').attr({'aria-selected':true});
					$(this).parents(tabGroup).find('#' + id).find('.js-inner-tabPanel:nth-child(1)').addClass(is_current);
					$(this).parents(tabGroup).find('#' + id).find('.js-inner-tabPanel:nth-child(1)').attr({'aria-hidden':false});
				}
			}
		});

		const $innerTab =  $('.js-inner-tab');
		const innerTab =  '.js-inner-tab[role="tab"]';
		const innerPanel = '.js-inner-tabPanel[role="tabpanel"]';

		$innerTab.on('click',function(){
			let select = $(this).attr('aria-selected');
			let id = $(this).attr('aria-controls');

			if (select === 'true') {
				return false;
			}

			$(this).parents(tabGroup).find('.js-inner-tab, .js-inner-tabPanel').removeClass(is_current);
			$(this).parents(tabGroup).find(innerPanel).attr('aria-hidden', true);
			$(this).parents(tabGroup).find(innerPanel).attr('aria-hidden', true);
			$(this).parents(tabGroup).find('#' + id).attr('aria-hidden', false);
			$(this).parents(tabGroup).find('#' + id).addClass(is_current);
			$(this).parents(tabGroup).find(innerTab).attr('aria-selected', false);
			$(this).addClass(is_current);
			$(this).attr('aria-selected', true);
		});
	});
}
