export default function() {
	const header_h = $('.l-header').outerHeight();
	const headerSide_h = $('.l-header-side').outerHeight();
	const urlHash = location.hash;
	const animeSpeed = 500;

	$('a[href^="#"]:not(.no-scroll)').on('click',function(){
		let href = $(this).attr('href');
    let target = $(href == "#" || href == "" ? 'html' : href);
		let position = target.offset().top;

	　if(document.URL.match('/recruit/')) {
			if(window.innerWidth < 767) {
				position = target.offset().top - headerSide_h;
				$('html, body').animate({
					scrollTop: position
				},animeSpeed);
			} else {
				position = target.offset().top - 100;
				$('html, body').animate({
					scrollTop: position
				},animeSpeed);
			}
		}else {
			position = target.offset().top - header_h;

			$('html, body').animate({
				scrollTop: position
			},animeSpeed);
		}
		return false;
	});

	if(urlHash){
    let $target = $(urlHash);
    if($target.length){
      let position = $target.offset().top - header_h;
      $("html, body").animate({scrollTop:position}, 1);
    }
  }
}
