export default function() {
	const $navBtn = $('.js-gnav');
	const $navWrapper = $('.js-gnav-wrapper');
	const $navTarget = $('.js-gnav-target');
	const $navItem = $('.p-gnav__item');
	const $body = $('body');
	const is_active = 'is-active';
	const is_show = 'is-show';

	$(window).on('load', function(){
		$navTarget.addClass('is-transition');
		$('.l-nav-side__line-wrap').addClass('is-transition');
	});

	$navBtn.on('click', function(){
		searchNavClose();
		langNavClose();
		$navBtn.toggleClass(is_active);

		if($navBtn.hasClass(is_active)) {
			$body.css({overflow:'hidden'});
			$navWrapper.attr({'aria-expanded':'true'});
			$navTarget.attr({'aria-hidden':'false'});
			$navTarget.addClass(is_show);
			$navItem.addClass(is_active);
			$('.l-nav-side__line-wrap').removeClass(is_show);
		}else {
			$body.css({overflow:'auto'});
			$navWrapper.attr({'aria-expanded':'false'});
			$navTarget.attr({'aria-hidden':'true'});
			$navTarget.removeClass(is_show);
			$navItem.removeClass(is_active);
		}
	});

	if($('.p-gnav').length) {
		const $navTrigger = $('.p-gnav-trigger');
		const localNav = '.p-lcnav';
		const gnavTriggerText = '.p-gnav-trigger__txt';
		const gnavTriggerHoverText = '.p-gnav-trigger__txt-wrap';

		if(window.innerWidth > 1301) {
			$navTrigger.hover(
				function() {
					$(this).addClass(is_active);
					$(this).find(gnavTriggerText).fadeOut();
					$(this).find(gnavTriggerHoverText).fadeIn();
					$(this).find(localNav).stop().slideDown();
				}, function() {
					$(this).removeClass(is_active);
					$(this).find(gnavTriggerHoverText).fadeOut();
					$(this).find(gnavTriggerText).fadeIn();
					$(this).find(localNav).stop().slideUp();
				}
			);
		}
		else {
			$navTrigger.on('click',function(){
				$(this).toggleClass(is_active);
				$(this).find(localNav).stop().slideToggle();
			});
		}
	}

	const $langBtn = $('.p-subnav-lang__btn');
	const $langListWrap = $('.p-subnav-lang__list-wrap');
	const $langText = $('.p-subnav-lang__txt');
	const $searchBtn = $('.p-subnav-search__btn');
	const $searchBox = $('.p-subnav-search__box');
	const $sttLangSelect = $('.p-subnav-lang__link');

	$langBtn.on('click',function(){
		searchNavClose();
		$(this).toggleClass(is_active);
		$langListWrap.stop().slideToggle();
	});

	$searchBtn.on('click',function(){
		langNavClose();
		$(this).toggleClass(is_active);
		$searchBox.stop().slideToggle();
	});

	$sttLangSelect.on('click',function(){
		$langBtn.removeClass(is_active);
		$langListWrap.stop().slideUp();
	});

	$sttLangSelect.on('DOMSubtreeModified', function() {
		$langText.text($('[data-stt-active]').text());
	});

	$(window).on('load', function() {
		$langText.text($('[data-stt-active]').text());
	})

	//リクルート ナビ
	const $navSide = $('.l-nav-side');
	const $navSideText = $('.l-nav-side__menu-txt');
	const $sideLangText = $('.p-side-gnav-lang__txt');
	const $sttLangSelectSide = $('.p-side-gnav-lang__link');

	if($navSide.length) {
		$navBtn.on('click', function(){
			if($navBtn.hasClass(is_active)) {
				$navSideText.text('CLOSE');
			}else {
				$navSideText.text('MENU');
			}
		});

		$('.js-line-btn').on('click', function(){
			$('.l-nav-side__line-wrap').addClass(is_show);
			$body.css({overflow:'auto'});
			$navBtn.removeClass(is_active)
			$navWrapper.attr({'aria-expanded':'false'});
			$navTarget.attr({'aria-hidden':'true'});
			$navTarget.removeClass(is_show);
			$navItem.removeClass(is_active);
		});

		$('.l-nav-side__line-close').on('click', function(){
			$('.l-nav-side__line-wrap').removeClass(is_show);
		});

		$sttLangSelectSide.on('DOMSubtreeModified', function() {
			$sideLangText.text($('[data-stt-active]').text());
		});

		$(window).on('load', function() {
			$sideLangText.text($('[data-stt-active]').text());
		})
	}

	//クローズナビ
	function langNavClose() {
		$langBtn.removeClass(is_active);
		$langListWrap.stop().slideUp();
	}

	function searchNavClose() {
		$searchBtn.removeClass(is_active);
		$searchBox.stop().slideUp();
	}
}
