export default function() {
	const $acc = $('.js-acc');
	const accOpen = 'is-open';

	$acc.each(function () {
		const $this = $(this);
		const $accContent = $('#' + $this.attr('aria-controls'));

		$this.on('click', function() {
			const isExpanded = $this.attr('aria-expanded') === 'true';

			$this.toggleClass(accOpen, !isExpanded);
			$accContent.slideToggle(300).toggleClass(accOpen, !isExpanded);

			$this.attr('aria-expanded', !isExpanded);
			$accContent.attr('aria-hidden', isExpanded);
		});
	});
}
