import anchorLink from './module/anchorLink';
import changeHeaderHeight from './module/changeHeaderHeight';
import spFillHeight from './module/spfillheight';
import spriteSvg from './module/spritesvg';
import nav from './module/nav';
import tab from './module/tab';
import modal from './module/modal';
import scrollbar from './module/scrollbar';
import masonryContent from './module/masonry';
import accordion from './module/accordion';
import pageTop from './module/pageTop';
import {autoHeight} from "./module/autoHeight";
import {select} from "./module/select";

//init
//---------------------------------------------------------
$(function () {
	spriteSvg();
	changeHeaderHeight();
	tab();
	nav();
	modal();
	accordion();
	pageTop();

	if($('.p-masonry-content').length) {
		masonryContent();
	};

	if($('.js-select').length) {
		select();
	}
});

$(window).on('resize load', function(){
	scrollbar();
	anchorLink();
	spFillHeight();
	autoHeight(document.getElementsByClassName('l-footer__inner-menu-ttl'));
});
