export default function() {
	const is_active = 'is-active';
	const is_current = 'is-current';
	const is_open = 'is-open';
	const $masonryWrap = $('#masonryWrap')
	const $masonryContent = $('#masonryContent');
	const $masonryItems = $('.js-masonry-items');
	const masonryItemsMt = parseInt($masonryItems.css('margin-top'));
	const $masonryItem = $('.p-masonry-item');
	const $readMore = $('.p-masonry-readMore');
	const readMoreHeight = $readMore.outerHeight();
	const $readMoreBtn = $('.js-readMore-trigger');
	const $filterBtn = $('.js-filter').find('button');
	const menuHeight = $('.js-masonry-menu').outerHeight();

	// フィルターボタン
	$filterBtn.each(function() {
		$(this).on('click', function() {
			const selected = $(this).val();

			$filterBtn.removeClass(is_current);
			$(this).addClass(is_current);

			$('.p-masonry-content').removeClass(function(index, className) {
				return (className.match(/\p-masonry-content--\S+/g) || []).join(' ');
			}).addClass('p-masonry-content--' + selected);

			masonrySelector();
			readMore();
		});
	});

	// masonry
	$masonryItems.imagesLoaded(function() {
		masonrySelector();
	});

	// masonry item ホバー処理
	if(window.innerWidth > 768) {
		$masonryItem.hover(
			function() {
				$(this).addClass(is_active);
			},function() {
				$(this).removeClass(is_active);
			}
		);
	}else {
		$masonryItem.on('click', function() {
			$(this).toggleClass(is_active);
		})
	}

	// read more open
	$(window).on('load',function(){
		const masonryItemsHeight = $masonryItems.outerHeight();
		$readMoreBtn.on('click', function() {
			$(this).toggleClass(is_active);
			if($masonryWrap.hasClass(is_open)) {
				removeOpenClass();
				if(window.innerWidth > 768) {
					$masonryContent.css({height: 1210});
				}else {
					$masonryContent.css({height: 880});
				}
				$('html,body').animate({
					scrollTop: $masonryWrap.offset().top - 80
				},500);
			}else {
				addOpenClass();
				if($('.js-masonry-menu').length) {
					$masonryContent.css({height: masonryItemsHeight + readMoreHeight + menuHeight + masonryItemsMt});
				}else {
					$masonryContent.css({height: masonryItemsHeight + readMoreHeight + masonryItemsMt});
				}
			}
		});

		readMore();
	})

	// read more
	function readMore() {
		const masonryItemsHeight = $masonryItems.outerHeight();
		if(window.innerWidth > 768) {
			toggleReadMore(1510);
		}else {
			toggleReadMore(880);
		}
		function toggleReadMore(hideHeight) {
			if(masonryItemsHeight > hideHeight) {
				$readMore.show();
				$masonryContent.css({height: hideHeight});
			}else {
				$readMore.hide();
				$masonryContent.css({height: masonryItemsHeight + menuHeight + masonryItemsMt});
			}
		}
	}

	//masonrySelector
	function masonrySelector() {
		$masonryItems.masonry({
			itemSelector: '.p-masonry-item',
			columnWidth: '.p-masonry-gutter',
			gutter: '.p-masonry-sizer',
		});
	}

	//masonry add remove class
	function addOpenClass() {
		$masonryWrap.addClass(is_open);
		$masonryContent.addClass(is_open);
	}

	function removeOpenClass() {
		$masonryWrap.removeClass(is_open);
		$masonryContent.removeClass(is_open);
	}
}
