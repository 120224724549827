const autoHeight = (elem) => {
	const elemArray = new Array;
	let elemMaxHeight = 0;

	Array.prototype.forEach.call(elem, function(e) {
		e.style.height = '';
		elemArray.push(e.clientHeight);
	});

	elemMaxHeight = Math.max.apply(null, elemArray);

	Array.prototype.forEach.call(elem, function(e) {
		e.style.height = elemMaxHeight + 'px';
	});
}

export {autoHeight}
