export default function() {
	if(window.innerWidth > 767) {
		$(window).on('scroll', function() {
			const scrollTop = $(window).scrollTop();
			const $header = $('.l-header');
			const is_fixed = 'is-fixed';

			if (scrollTop > 20) {
				$header.addClass(is_fixed);
			} else {
				$header.removeClass(is_fixed);
			};
		});
	}
}
