export default function() {
	let top = 0;

	$('.js-modal').on('click', function(e) {
		e.preventDefault();
		const $el = $(this);
		const target = $el.val();
		top = $(window).scrollTop();
		$(target).fadeIn();
		$('body').addClass('is-fix');
		$('body').css('top', (top * -1) + 'px');
	})

	$('.js-modal-close').on('click', function(e) {
		e.preventDefault();
		$('body').removeClass('is-fix');
		$('body').removeAttr('top');
		$(window).scrollTop(top);
		$('.js-modal-container').fadeOut();
	})
}
